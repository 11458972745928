.footeitem{

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-content: center;
    padding: 7rem 2rem;
}

.imageside{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 2rem ;
}

.icons{
    margin: 1rem;
    font-size: 3rem;
    color: #ed1864;
}
.messageside input{
    width: 50%;
    padding: 0.5rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.messageside h1{
    font-size: 1.4rem;
    color: #ed1864;
    width: 100%;
}

.feetnoot{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #111111;
    color: #fff;
}

.sjehgdy{
    margin: 1rem;
}

@media screen and (max-width:820px){


    .footeitem{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        justify-content: center;
        padding: 7rem 2rem;
    } 

    .messageside p{
        width: 100%;
    }
  
  }
