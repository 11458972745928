.globalconatiner{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
   
    

}

.globalrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    background-color: #111111e8;
    color: white;
    padding: 7rem 2rem;
}

.globalcontent{
    padding: 2rem;   
}

.gloabalheader{
    color: #ed1864;
    font-size: 3rem;
    font-weight: bolder;

}

.globalcotent{
    line-height: 2rem;
}

.globalcontent button{
    margin-top: 1rem;
    background-color: #ed1864;
    color: white;
    padding: 0.34rem 1rem;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

@media screen and (max-width:720px){

    .gloabalheader{
        color: #ed1864;
        font-size: 2rem;
        font-weight: bolder;
    
    }

    .globalrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        /* height: 100vh; */
        background-color: #111111;
        color: white;
        padding: 4rem 0rem;
    }

    
.globalrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    background-color: #111111e8;
    color: white;
    padding: 2rem 2rem;
}
    
  
  }




