

.trankcontainer{
    background-color: rgba(0, 0, 0, 0.658);

    display: flex;
    justify-content: center;
    /* align-items: center; */
    color: black;
   
}



.trackformahs h2{
    text-align: start;
}
.Trackingcontainer{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}

.trackingcontentr{
    background-color: #111111d2;
    min-height: 100vh;  
    padding: 2rem;
    text-align: center;
}

.trackingcontentr button{
    margin-top: 1rem;
    border: none !important;
}
.inputselction111{
    width: 50%;
    margin:  auto auto;
    padding: 2rem;
}
.bannercontainer{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.trankcontainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.inputselction111{
    /* padding: 1.5rem; */
    background-color: wheat;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
.inputselction{
    padding: 1.5rem;
    margin: 7rem;
    background-color: wheat;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.textsections{
    padding: 0 2rem 0 0;
    margin: 7rem 0;
    line-height: 2rem;
    color: whitesmoke;
}

.textsections h2{
    font-weight: bolder;
    font-size: 2.5rem;
}

.modalcontainer h1{
    font-size: 1rem !important;
    background-color: #ed1864;
    color: white;
    padding: 0.43rem;
}


.list-group-item{
    font-size: 0.9rem !important;
}

@media screen and (max-width:930px){
    .inputselction{
        padding: 1.5rem;
        margin: 3rem;
        background-color: wheat;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
    
    .textsections{
        padding: 0 1rem 0 0;
        margin: 4rem 0;
        line-height: 2rem;
        color: whitesmoke;
    }  .banksecurecontent{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        text-align: center;
    }
  }


  @media screen and (max-width:720px){
    .trankcontainer{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
    }

    .textsections{
        padding: 0 2rem 0 0;
        margin: 3rem;
        line-height: 2rem;
        color: whitesmoke;
    }

    .inputselction{
        padding: 1.5rem;
        margin: 2rem;
        background-color: wheat;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }

    .inputselction111{
        width: 100%;
        margin:  auto auto;
        padding: 2rem;
    }
    
  
  }